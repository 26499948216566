import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SplashGuard implements CanActivate{

  firstLoad = true;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(this.firstLoad){
      this.firstLoad = false;
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
