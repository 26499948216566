import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {Geolocation} from "@ionic-native/geolocation/ngx";
import {LocationAccuracy} from "@awesome-cordova-plugins/location-accuracy/ngx";


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, BrowserAnimationsModule, LazyLoadImageModule, IonicModule.forRoot({
    mode: "ios"
  }), AppRoutingModule, NgbModule, FontAwesomeModule, ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    // Register the ServiceWorker as soon as the app is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  })],
  providers: [Geolocation, LocationAccuracy, {provide: LocationStrategy, useClass: PathLocationStrategy}, {
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy
  }, {
    provide: LAZYLOAD_IMAGE_HOOKS,
    useClass: ScrollHooks
  }],
  bootstrap: [AppComponent],
})
export class AppModule {
}
