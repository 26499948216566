import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PackageGuard} from "./guard/package.guard";
import {SplashGuard} from "./guard/splash.guard";


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'destinations',
    canActivate: [PackageGuard],
    loadChildren: () => import('./pages/destinations/destinations.module').then(m => m.DestinationsPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/destination-details/destination-details.module').then(m => m.DestinationDetailsPageModule)
  },
  {
    path: 'splash',
    canActivate: [SplashGuard],
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
