import {action, observable} from 'mobx-angular';
import {Injectable} from '@angular/core';
import {IPackage} from "../models/package.model";
import {StorageService} from "../services/storage/storage.service";

@Injectable({providedIn: 'root'})
export default class PackageStore {
  @observable packages: IPackage[];

  constructor(private storageService: StorageService) {
    this.packages = [];
    const tmpPackages = this.storageService.getStorageObject('packages');
    if(tmpPackages){
      this.packages = tmpPackages;
    }
  }

  @action
  setPackages(packages: IPackage[]): void {
    this.packages = packages;
    this.storageService.setStorageObject('packages', this.packages);
  }


}
