import {Injectable} from '@angular/core';
import {IPackage} from '../../models/package.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  setSettingsData(name: string, data: any): void {
    try {
      console.log('Setting settings data');
      localStorage.setItem(name, data.toString());
    } catch (e) {
      console.log('Error: ', e);
    }
  }

  getSettingsData(name: string): any {
    try {
      const res = localStorage.getItem(name);
      if (res) {
        return res;
      }
    } catch (e) {
      console.log('Error: ', e);
    }

  }


  setStorageObject(name: string, data: any): void {
    try {
      console.log('Starting stringifying');
      const stringifyed = JSON.stringify(data);
      // console.log('Setting data: ', stringifyed);
      localStorage.setItem(name, stringifyed);
    } catch (e) {
      console.log('Error: ', e);
    }

  }

  getStorageObject(name: string): any {
    try {
      const res = localStorage.getItem(name);
      // console.log('Got data: ', res);

      if (res) {
        return JSON.parse(res);
      }
      return null;
    } catch (e) {
      console.log('Error: ', e);
    }

  }
}
