import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import PackageStore from "../store/package.store";

@Injectable({
  providedIn: 'root'
})
export class PackageGuard implements CanActivate {

  constructor(private packageStore: PackageStore, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (this.packageStore.packages.length === 0) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
