import {Component, ElementRef} from '@angular/core';
import {Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {Capacitor} from "@capacitor/core";
import {SplashScreen} from "@capacitor/splash-screen";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isBrowser = false;
  showTmpSplash = false;
  loadBackground = true;

  constructor(private router: Router, private platform: Platform,private elementRef: ElementRef) {
    console.log('Constructor created');
    this.isBrowser = Capacitor.getPlatform() === 'web';
    if(!this.isBrowser){
      this.router.navigate(['/splash']).then(()=>{
        this.loadBackground = true;
        console.log('Redirected');
        this.initializeApp();
      });
    }else{
      this.loadBackground = true;
    }

  }


  initializeApp(){
    this.platform.ready().then(async () => {
      SplashScreen.hide();
    });
  }
}
